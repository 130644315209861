import http from "./httpService";
import jwt from "jsonwebtoken";
import { apiUrl } from "../config.json";

const tokenKey = "tokenKspTmu";
const refreshTokenKey = "refreshTokenKspTmu";

//http.setJwt(getJwt());

export async function login(data) {
  const { data: token } = await http.post(`${apiUrl}/v1/auth`, data);
  localStorage.setItem(tokenKey, token.token);
  localStorage.setItem(refreshTokenKey, token.refreshToken);
}

export function loginWithJwt(token) {
  localStorage.setItem(tokenKey, token);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
}

export function getCurrentUser() {
  try {
    const token = localStorage.getItem(tokenKey);
    return jwt.decode(token);
  } catch (ex) {
    return null;
  }
}

export function getRefreshToken() {
  try {
    const refreshToken = localStorage.getItem(refreshTokenKey);
    return jwt.decode(refreshToken);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getRefreshToken,
};
